<template>
    <v-row dense id="dash">
         <v-col cols="12" md="12" lg="12" v-show="!axn" order="1" order-md="1">
            <AlertCard />
        </v-col>
        <v-col cols="12" :lg="(axn) ? 12 : 6" order="2" order-md="2">
            <div id="settings" class="d-flex fill-height elevation-2">

                <div class="settings-container">

                    <div class="settings-text pt-3" v-if="!axn">

                        <p class="text-center secondary--text settings-title subtitle-1 font-weight-medium mt-0 mb-2">Your Check-In Schedule</p>

                        <p class="secondary--text px-5 px-md-10 alert text-center subtitle-1 mb-1 mt-2 mt-md-4 lh-14" v-if="user.settings.active && user.settings.snoozed">
                            <v-icon large class="mb-3" color="primary">fa fa-bed</v-icon>
                            <br>
                            Your Check-Ins are snoozed until <br>{{  $moment.utc(user.settings.snoozed).tz(user.timezone).add('1', 'day').format('dddd, MMMM Do') }}.
                            <br>
                            <v-btn rounded class="mt-3" color="primary" :loading="activating" @click.native="updateSnooze()">
                                Turn Snooze Off
                            </v-btn>
                        </p>
                        <p class="secondary--text px-5 px-md-10 alert text-center subtitle-1mt-2 mt-md-4" v-if="!user.settings.active && !user.unsub && hasActiveContacts">
                            <v-icon large class="mb-3" color="primary">fa fa-bell-slash</v-icon>
                            <br>
                            Your Check-Ins have been turned off.
                            <br>
                            <v-btn rounded class="mt-3" color="primary" :loading="activating" @click.native="activate()">
                                Turn Check-Ins Back On
                            </v-btn>
                        </p>
                        <p class="secondary--text px-5 px-md-10 alert text-center subtitle-1 mb-1" v-if="!user.settings.active && user.unsub">
                            <v-icon x-large color="error" class="mt-3 mb-3">fa fa-exclamation-triangle</v-icon>
                            <br>
                            You've Blocked Our Check-Ins
                            <br>
                            Text "unstop" to (201) 899-4665 to un-block.
                        </p>
                        <p class="secondary--text px-5 px-md-10 alert text-center subtitle-1 mb-1" v-if="!hasActiveContacts || !user.contacts.length">
                            <v-icon color="error" class="mt-2" large>fa fa-exclamation-triangle</v-icon>
                            <span class="d-block subtitle-1 secondary--text mb-5 mt-3 lh-12">Your check-ins are <b>disabled</b> as you have no active emergency contacts.</span>
                            <v-btn rounded class="px-5 mb-2" color="primary" @click.native="$router.push('/app/contacts')">
                                Add an Emergency Contact
                            </v-btn>
                        </p>

                        <p v-if="settingsDisabled" class="text-center caption mb-0 lh-12">Disabled until check-in received.</p>

                        <v-list :disabled="settingsDisabled" :class="(settingsDisabled) ? 'py-0 no-bb mb-0 faded' : 'py-0 no-bb mb-0'" v-show="showSettings">
                            <!-- <v-list-item class="pl-1" @click="axn='frequency'">
                                <v-list-item-avatar class="mr-2">
                                    <v-avatar>
                                        <v-icon color="primary">fa fa-sms</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Send me <span class="px-0" @click="axn='frequency'">{{ itemCopy.frequency }}</span> {{ (itemCopy.frequency > 1) ? 'check-ins' : 'check-in' }}
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small class="mt-1" color="#999">fa fa-edit</v-icon>
                                </v-list-item-action>
                            </v-list-item> -->
                            <v-divider />
                            <v-list-item class="pl-1" @click="axn='days'">
                                <v-list-item-avatar class="mr-2">
                                    <v-avatar>
                                        <v-icon color="primary">fa fa-calendar-alt</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Send {{ (itemCopy.day_type > 3) ? ' on ' : '' }}<span :class="(itemCopy.day_type == 4) ? 'px-0 text-capitalize' : 'px-1'" @click="axn='days'" v-html="getDayString()"></span>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small class="mt-1" color="#999">fa fa-edit</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                            <v-list-item class="pl-1" @click="axn='times'">
                                <v-list-item-avatar class="mr-2">
                                    <v-avatar>
                                        <v-icon color="primary">fa fa-clock</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium no-trunctate">
                                        at <i class="dd-inline-block" @click="axn='times'" v-html="getTimeString()"></i>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small class="mt-1" color="#999">fa fa-edit</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider />
                            <v-list-item class="pl-1 pb-0" @click="axn='response_time'">
                                <v-list-item-avatar class="mr-2">
                                    <v-avatar>
                                        <v-icon color="primary">fa fa-exclamation-triangle</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Give me 
                                        <span class="px-1" @click="axn='response_time'">{{ itemCopy.response_time }}</span>
                                        hour{{ (itemCopy.response_time > 1) ? 's' : '' }}
                                        to reply
                                    </v-list-item-title>
                                    <v-list-item-subtitle>before alerting my contacts</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon small class="mt-1" color="#999">fa fa-edit</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>

                        <p class="px-2 px-md-0 text-center title mb-2 mb-md-5 hidden" v-if="showSettings">
                            Send me 
                            <span class="px-1" @click="axn='frequency'">{{ itemCopy.frequency }}</span>
                            {{ (itemCopy.frequency > 1) ? 'messages' : 'message' }} {{ (itemCopy.day_type > 3) ? 'on' : '' }} 
                            <span :class="(itemCopy.day_type == 4) ? 'px-1 text-capitalize' : 'px-1'" @click="axn='days'" v-html="getDayString()"></span>
                            <br class="hidden-md-and-up" v-if="itemCopy.day_type > 2" />
                            <br class="hidden-sm-and-down" v-if="itemCopy.day_type == 4" />
                            at <i class="d-inline-block" @click="axn='times'" v-html="getTimeString()"></i> to see if I'm okay.
                        </p>
                        <p class="text-center title pt-2 pt-sm-0 mb-1 pb-0 hidden" v-if="showSettings">
                            If I don't respond within 
                            <span class="px-1" @click="axn='response_time'">{{ itemCopy.response_time }}</span>
                            hour{{ (itemCopy.response_time > 1) ? 's' : '' }}, notify my <br class="hidden-md-and-down" /> emergency contact{{ (user.contacts.length == 1) ? '' : 's' }}.
                        </p>

                    </div>

                    <v-card v-if="axn" class="edit-card elevation-0">
                        <v-card-text class="pt-0 pb-0 secondary--text text-center" :key="'ct' + key">
                            <div class="d-flex align-center edit-area justify-center">
                                <template v-if="axn=='frequency'">
                                    <div>
                                        <p class="subtitle-1 text-md-h5 lh-13 font-weight-medium mb-3 mb-md-5">How many check-ins should we <br class="hidden-sm-and-up" /> send you each day?</p>
                                        <div class="flex-center">
                                            <v-radio-group hide-details class="mt-0" :row="!$vuetify.breakpoint.smAndDown" v-model="itemCopy.frequency">
                                                <v-radio class="font-weight-medium" color="primary" label="One Check-in" :value="1" />
                                                <v-radio class="font-weight-medium" color="primary" label="Two Check-ins" :value="2" />
                                                <v-radio class="font-weight-medium" color="primary" label="Three Check-ins" :value="3" />
                                            </v-radio-group>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="axn=='days'">
                                    <div class="pb-2">
                                        <p class="subtitle-1 text-md-h5 lh-13 font-weight-medium mb-1 mb-sm-3 mb-md-4">On what days should we send <br class="hidden-sm-and-up" /> check-ins to you?</p>
                                        <div class="flex-center">
                                            <v-radio-group class="my-0" hide-details v-model="itemCopy.day_type" @change="setDayType()">
                                                <v-radio class="font-weight-medium" color="primary" label="Everyday" :value="1" />
                                                <v-radio class="font-weight-medium" color="primary" label="Let Me Choose" :value="4" />
                                            </v-radio-group>
                                        </div>
                                        <Days :item="itemCopy" v-if="itemCopy.day_type == 4" />
                                    </div>
                                </template>
                                <template v-if="axn=='times'">
                                    <div>
                                        <p class="subtitle-1 text-md-h5 lh-13 font-weight-medium mb-3 mb-sm-5" v-if="itemCopy.frequency > 1">What times should we send your <br class="hidden-sm-and-up" /> check-ins to you?</p>
                                        <p class="subtitle-1 text-md-h5  lh-13 font-weight-medium mb-3 mb-sm-5" v-else>What time should we send your <br class="hidden-sm-and-up" /> check-in to you?</p>
                                        <Times :item="itemCopy" @updated="itemCopy = $event" />
                                    </div>
                                </template>
                                <template v-if="axn=='response_time'">
                                    <div>
                                        <p class="subtitle-1 text-md-h5  lh-13 font-weight-medium md-5">If you fail to check in, how long <br class="hidden-sm-and-up" /> should we wait to notify <br /> your emergency contacts?</p>
                                        <div class="flex-center">
                                            <v-select 
                                                v-model="itemCopy.response_time"
                                                :items="responseItems()"
                                                outlined
                                                prefix="Wait "
                                                prepend-inner-icon="fa fa-clock"
                                                color="primary"
                                                hide-details
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </v-card-text>
                        <v-card-actions class="Dno-br-bot card-actions cnc-actions mt-1">
                            <v-spacer class="hidden-sm-and-down" />
                            <v-btn class="mr-md-5" exact outlined text @click.native="resetSchedule()">Cancel</v-btn>
                            <v-spacer class="hidden-md-and-up" />
                            <v-btn color="primary" :disabled="!changed" class="px-5" :loading="saving" @click.native="saveSchedule()">Save Changes</v-btn>
                            <v-spacer class="hidden-sm-and-down" />
                        </v-card-actions>
                    </v-card>
                </div>
            </div>
        </v-col>
        <v-col cols="12" md="12" lg="6" v-show="!axn" order="3" order-md="3">
            <StatsCard />
        </v-col>
        <v-col cols="12" md="12" order="3" v-if="showTasks">
            <Tasks />
        </v-col>
        <v-col cols="12" md="12" order="3">
            <StatusBar @cancelOneTime="$emit('cancelOneTime')" />
        </v-col>
        <v-col cols="12" md="12" order="4">
            <Tools />
        </v-col>
        <v-col cols="12" class="py-0" order="5" v-show="!axn">
            <p class="text-center text-md-left secondary--text settings-title subtitle-1 font-weight-medium mt-2 mb-0">
                <v-icon class="mr-1 ml-md-1" color="secondary" size="17">fa fa-user-cog</v-icon> Settings
            </p>
        </v-col>
        <v-col cols="12" md="6" class="dhidden-md-and-down pt-md-0" v-show="!axn" order="6">
            <UserInfo />
        </v-col>
        <v-col cols="12" lg="6" class="pt-md-0" v-show="!axn" order="7">
            <UserGeo />
        </v-col>
    </v-row>
</template>
<script>

import { mapState } from 'vuex'
import AlertCard from '@/components/user/dashboard/AlertCard'
import Days from '@/components/user/dashboard/checkin/Days'
import StatsCard from '@/components/user/dashboard/StatsCard'
import StatusBar from '@/components/user/dashboard/StatusBar'
import Tools from '@/components/user/dashboard/Tools'
import Times from '@/components/user/dashboard/checkin/Times'

export default {
    name: 'UserDashboardCheckinSettings',
    props: {
        onboarding: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    components: {
        AlertCard,
        Days,
        StatsCard,
        StatusBar,
        Times,
        Tools,
        UserInfo: () => import('@/components/user/dashboard/UserInfo'),
        UserGeo: () => import('@/components/user/dashboard/UserGeo'),
        Tasks: () => import('@/components/user/dashboard/Tasks'),
    },
    computed: {
        ...mapState(['user']),
        settingsDisabled(){
            return this.$moment.utc(this.user.next_checkin).tz(this.user.timezone).isBefore() || this.user.on_alert == 1;
        },
        showTasks(){
            let ux = {};
            if(this.user.ux){
                ux = JSON.parse(this.user.ux);
            }
            if(ux.taskNotes!=false && !this.user.notes.length){
                return true;
            }
            if(ux.taskMoreContacts!=false && this.user.contacts.length <= 3){
                return true;
            }
            if(ux.taskUserImg!=false && !this.user.image){
                return true;
            }
            return false;
        },
        headingClass(){
            let classes = 'checkin-heading d-flex align-center';
            if(this.snoozing){
                classes += ' hidden';
            }
            return classes;
        },
        changed(){
            if(this.daysChanged()){
                return true;
            }
            if(this.itemOrig.day_type !== this.itemCopy.day_type){
                return true;
            }
            if(this.itemOrig.frequency !== this.itemCopy.frequency){
                return true;
            }
            if(this.itemOrig.response_time != this.itemCopy.response_time){
                return true;
            }
            if(
                this.itemOrig.time_1 !== this.itemCopy.time_1
                || this.itemOrig.time_2 !== this.itemCopy.time_2
                || this.itemOrig.time_3 !== this.itemCopy.time_3
            ){
                return true;
            }
            return false;
        },
        showSettings(){
            return !this.snoozing && this.user.contacts.length 
                && this.user.settings.active && !this.user.settings.snoozed
                && this.hasActiveContacts;
        },
        hasActiveContacts(){
            let active = 0;
            for(let i in this.user.contacts){
                if(this.user.contacts[i].active){
                    ++active;
                }
            }
            return active > 0;
        }
    },
    data: () => ({
        activating: false,
        axn: null,
        customDays: false,
        changeTime: false,
        changeTimeVal: null,
        changeTimePos: {},
        dayContd: false,
        errors: null,
        freqIncreased: false,
        item: {
            defaulted: true,
            day_type: 1,
            frequency: 1,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
            time_1: "12:30",
            time_2: "17:30",
            time_3: "20:30",
            response_time: 2
        },
        itemCopy: {},
        itemOrig: {},
        key: 0,
        times: [],
        loading: false,
        ready: true,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        saving: false,
        snoozing: false,
    }),
    methods: {
        initData(){
            this.itemOrig = JSON.parse(JSON.stringify(this.user.settings));
            this.itemCopy = JSON.parse(JSON.stringify(this.user.settings));
            if(!this.user.phone_verified && !this.onboarding){
                this.$eventHub.$emit('verifyPhone');
            }
            if(this.onboarding){
                this.itemOrig = JSON.parse(JSON.stringify(this.item));
                this.itemCopy = JSON.parse(JSON.stringify(this.item));
            }
        },
        daysChanged(){
            let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            for(let i in days){
                if(this.itemCopy[days[i]] !== this.itemOrig[days[i]]){
                    return true;
                }
            }
        },
        responseItems(){
            let items = [];
            let suffix;
            for(let i=1;i<25;++i){
                suffix =  (i==1) ? ' hour' : ' hours';
                items.push({
                    value: i,
                    text: i + suffix
                });
            }
            return items;
        },
        setDayType(){
            // this.clearDays();
            if(this.itemCopy.day_type == 4){
                // set defaults if previous was not 'custon' day type
                if(this.itemOrig.day_type !== 4){
                    this.clearDays(true);
                }
            }
            // this.itemCopy.day_type = val;
        },
        clearDays(setAllTrue){
            let newVal = (setAllTrue) ? true :  false;
            this.itemCopy.monday = newVal;
            this.itemCopy.tuesday = newVal;
            this.itemCopy.wednesday = newVal;
            this.itemCopy.thursday = newVal;
            this.itemCopy.friday = newVal;
            this.itemCopy.saturday = (setAllTrue) ? false : newVal;
            this.itemCopy.sunday = (setAllTrue) ? false: newVal;
        },
        getDayString(){
            if(this.itemCopy.day_type == 2){
                return 'Every Other Day';
            }else if(this.itemCopy.day_type == 3){
                return 'On Weekends Only';
            }else if(this.itemCopy.day_type == 4){
                return this.getCustomDayString();
            }else{
                return 'Every Day';
            }
        },
        getCustomDayString(){
            let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            let thisDays = [];
            let hasDays = [];
            this.dayContd = false;
            
            for(let i in days){
                if(this.itemCopy[days[i]]){
                    hasDays.push(days[i]);
                }else{
                    hasDays.push(null);
                }
            }

            // if(hasDays.length == 7){
            //     this.itemCopy.day_type = 1;
            //     return;
            // }

            let start = null;
            let end = null;
            for(let i in hasDays){
                // console.log(i, 'start', start, 'hasdays[i]', hasDays[i], 'end', end);
                if(!start && hasDays[i] !== null){
                    // console.log('here1');
                    start = hasDays[i];
                    if(i==6){
                        thisDays.push(start + 's');
                    }
                }else if(hasDays[i] === null){
                    if(hasDays[i-1] == start){
                        if(start !== null){
                            thisDays.push(start + 's');
                        }
                    }else{
                        end = hasDays[i-1];
                        thisDays.push(start + 's-' + end+'s');
                    }
                    start = null;
                    end = null;
                }else if(i==6){
                    end = hasDays[i];
                    thisDays.push(start + 's-' + end+'s');
                    // console.log('here2');
                }
            }

            // console.log('thisDays', thisDays);

            if(!hasDays.length){
                return '...';
            }

            if(hasDays.length == 1){
                return thisDays[0];
            }
            
            let lastDay = thisDays[thisDays.length - 1];
            thisDays.pop();
            let ds = (thisDays.length > 1) ? thisDays.join(', ') : thisDays.join('');
            ds += (thisDays.length > 0) ? ' <i>and</i> ' + lastDay : lastDay;
            return ds;
        },
        getTimeString(){
            let ts = '';
            ts = '<span class="time-entry-1">' + this.formattedTime(this.itemCopy.time_1) + '</span>';
            if(this.itemCopy.frequency > 1){
                let concat = (this.itemCopy.frequency == 2) ? ' and ' : ', ';
                ts += concat + '<span class="time-entry-2">' + this.formattedTime(this.itemCopy.time_2) + '</span>';
            }
            if(this.itemCopy.frequency > 2){
                ts += ' and ' + '<span class="time-entry-3">' + this.formattedTime(this.itemCopy.time_3) + '</span>';
            }
            return ts;
        },
        handleTimeClick(e){
            this.changeTimePos.x = e.clientX;
            this.changeTimePos.y = e.clientY;
            if (e.target.matches('.time-entry-1')) {
                this.changeTimeVal = 'time_1';
            }
            if (e.target.matches('.time-entry-2')) {
                this.changeTimeVal = 'time_2';
            }
            if (e.target.matches('.time-entry-3')) {
                this.changeTimeVal = 'time_3';
            }
            this.changeTime = this.itemCopy[this.changeTimeVal];
        },
        handleTimeChange(time){
            this.itemCopy[this.changeTimeVal] = time;
        },
        formattedTime(ts){
            if(!ts){
                return '';
            }
            let parts = ts.split(':');
            let hour = parts[0];
            let ampm = 'am';
            if(hour >= 12){
                ampm = 'pm';
                hour = hour-12;
            }
            if(hour == 0){
                hour = '12';
            }
            return hour + ':' + parts[1] + '' + ampm;
        },
        handleDaysCancel(){
            if(this.itemOrig.day_type !== 4){
                this.itemCopy.day_type = this.itemOrig.day_type;
            }
            this.customDays = false;
        },
        saveSchedule(){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/checkinsettings/1', 
                method: 'put',
                data: this.itemCopy,
                apiKey: this.user.api_token || null
            };
            if(this.itemCopy.frequency > this.itemOrig.frequency){
                this.freqIncreased = true;
            }
            this.trackSettingsChanges();
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.itemOrig = JSON.parse(JSON.stringify(res.data));
                    this.itemCopy = JSON.parse(JSON.stringify(res.data));
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your check-in settings have been updated!'
                    });
                    this.$emit('updated');
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.axn = null;
                ++this.key;
                this.handleChangeStates();
            });
        },
        trackSettingsChanges(){
            let action = 'Unknown';
            if(this.axn == 'response_time'){
                action = 'Changed response time';
            }
            if(this.axn == 'frequency'){
                action = 'Changed check-in frequency';
            }
            if(this.axn == 'times'){
                action = 'Changed check-in time(s)';
            }
            if(this.axn == 'days'){
                action = 'Changed check-in days';
            }
            this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                action_name: 'Updated Schedule - ' + action
            }});
        },
        handleChangeStates(){
            // prompt for new times if frequency increased
            if(this.freqIncreased){
                this.axn = 'times';
                this.freqIncreased = false;
            }
        },
        resetSchedule(){
            this.itemCopy = JSON.parse(JSON.stringify(this.itemOrig));
            this.axn = null;
        },
        activate(){ 
            this.activating = true;
            let request = {
                endpoint: '/deactivate', 
                method: 'post',
                data: {
                    activate: true
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = 'Check-Ins have been turned back on!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Reactivated Check-Ins'
                    }});
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.activating = false;
            });
        },
        updateSnooze(){ 
            this.activating = true;
            let request = {
                endpoint: '/snooze', 
                method: 'post',
                data: {
                    unsnooze: true
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = 'Snooze has been turned off!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Disabled Snooze'
                    }});
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.activating = false;
            });
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        itemCopy: {
            handler(){
                if(this.onboarding){
                    this.$emit('updated', this.itemCopy);
                }
                // console.log(this.itemCopy);
            },
            deep: true
        }
    }
}
</script>