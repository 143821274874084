<template>
    <div>
        <v-card class="elevation-2 fill-height bg-wavelines-secondary-dark" color="secondary" dark v-if="user.contacts.length">
            <v-card-text class="px-3 pb-4 pt-5 py-md-6 pb-lg-4 pt-lg-5 mb-2 text-center fill-height d-flex align-center justify-center">
                <div style="width: 100%;">
                    <template v-if="!user.on_alert">
                        <template v-if="$moment.utc(user.next_checkin).tz(user.timezone).isBefore()">
                            <v-icon color="error" large>fa fa-bell</v-icon>
                                <h3 class="subtitle-1 font-weight-bold text-md-h6 white--text mt-3 lh-14">
                                Your Last check-in has not been answered!
                            </h3>
                            <span class="body-2 white--text">
                                Sent {{ $moment.utc(user.next_checkin).tz(user.timezone).fromNow() }}
                                <br>
                                <v-btn class="mt-2 mb-2" rounded color="primary" small :loading="activating" @click.native="checkIn()">Check In Now</v-btn>
                            </span>
                        </template>
                        <template v-else>
                            <template v-if="user.settings.active">
                                <v-icon color="primary" large>fa fa-bell</v-icon>
                                <h3 class="subtitle-1 font-weight-bold text-md-h6 white--text mt-3 lh-14">
                                    <v-icon color="white" class="pr-1 d-none">fa fa-arrow-circle-right</v-icon> Your next check-in is 
                                    {{ nextCheckIn }}.
                                </h3>
                                <span class="body-2 white--text">
                                    {{ $moment.utc(user.next_checkin).tz(user.timezone).format('h:mma [on] dddd, MMMM Do') }}
                                </span>
                            </template>
                            <template v-else>
                                <v-icon color="error" large>fa fa-bell</v-icon>
                                <h3 class="title font-weight-bold text-md-h6 error--text mt-3 lh-14">
                                    Your check-ins are disabled. 
                                </h3>
                                <span class="body-2 secondary--text">
                                    Check-ins will resume when turned back on.
                                </span>
                            </template>                            
                        </template>
                    </template>
                    <template v-else>
                        <v-icon color="error" large>fa fa-exclamation-triangle</v-icon>
                        <h3 class="title font-weight-bold text-md-h6 white--text mt-2 mb-1 lh-14">
                            Your Contacts have been Alerted!
                        </h3>
                        <span class="body-2 white--text">
                            Alert Sent {{ $moment.utc(user.last_alert).tz(user.timezone).fromNow() }}
                            <span class="body-2 d-block white--text">
                                {{ $moment.utc(user.next_checkin).tz(user.timezone).format('h:mma [on] dddd, MMMM Do') }}
                            </span>
                            <v-btn class="mt-2 mb-2" color="primary" rounded small :loading="activating" @click.native="checkIn()">Check In Now</v-btn>
                        </span>
                    </template>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'UserDashboardAlert',

    props: {
    },
    computed: {
        ...mapState(['user']),
        nextCheckIn(){
            let checkInTime = this.$moment.utc(this.user.next_checkin);
            let onceTime;
            if(this.user.once_checkin){
                onceTime = this.$moment.utc(this.user.once_checkin);
                if(onceTime.isBefore(checkInTime) && onceTime.isAfter(this.$moment.utc())){
                    checkInTime = onceTime;
                }
            }
            return checkInTime.tz(this.user.timezone).fromNow()
        }
    },
    data: () => ({
        activating: false,
        axn: null,
        thisTimezone: '',
    }),
    methods: {
        checkIn(){ 
            this.activating = true;
            let request = {
                endpoint: '/appcheckin', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = 'Check-in successfully sent!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$appFn.trackEvent({ action: 'user_setting_update', options: {
                        action_name: 'Checked-in via app.'
                    }});
                    this.$eventHub.$emit('refreshUser');
                }
            }).finally(()=>{
                this.activating = false;
            });
        },
    }
}
</script>
