<template>
    <v-row dense class="settings-tools mt-0 mt-md-1">
        <v-col cols="12" md="12" lg="12" class="pt-0 pb-md-0">
            <p class="text-center text-md-left secondary--text settings-title subtitle-1 font-weight-medium mb-1">
                <v-icon class="mr-1 ml-md-1" size="17" color="secondary">fa fa-tools</v-icon> Tools
            </p>
            <v-card color="white" class="dd-md-none d-lg-flex elevation-2">
                <v-card-text class="pa-0 pt-0 pt-0 pb-0 px-lg-3">
                    <v-list :three-line="$vuetify.breakpoint.smAndDown" class="pt-0 pb-0 px-4 px-lg-0 no-bb no-truncate transparent">
                        <v-list-item class="pl-0" @click="axn='share'; $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: Tools: Share Notes Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-share-square</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Share Your Emergency Notes
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Send any of your emergency notes to one of your contacts or to another mobile number.
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="normal-text hidden-md-and-up" v-if="user.has_shares">
                                    <v-btn small text outlined class="mt-1" @click.native.prevent="$router.push({ name: 'activity', params: {view:'shares'}})" color="primary">Manage Shared Notes</v-btn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="hidden-sm-and-down align-self-center" v-if="user.has_shares">
                                <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{on}">
                                        <v-btn icon v-on="on" color="primary" @click.prevent="$router.push({ name: 'activity', params: {view:'shares'}})"><v-icon>fa fa-cog</v-icon></v-btn>
                                    </template>
                                    <span>Manage Your Shares</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                        <v-list-item class="pl-0" href="/alert/preview" @click="$appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: Tools: Preview Alert Page Clicked' }});" target="_blank">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-search</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Preview Your Alert Page
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    See what your emergency contacts will be shown should you fail to respond to your check-in.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item class="pl-0" @click="sendTestSMS(); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: Tools: Send Test SMS Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-sms</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    {{ (testSending) ? 'Sending...' : 'Send a Test Message' }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Send yourself a test message to verify {{ user.phone }} can receive our check-ins.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog :value="true" v-if="axn=='share'" overlay-opacity="0.8" overlay-color="#000" @input="handleShareClose()" max-width="500px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 secondary--text text-center">Share <span class="hidden-sm-and-down">Emergency</span> Notes</h3>
                    <v-btn absolute right icon @click.native="axn=null"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2 secondary--text">
                    <v-divider />
                    <template v-if="shared">
                        <p class="text-center mt-5 mb-2"><v-icon large color="success">fa fa-share-square</v-icon></p>
                        <p class="title text-center mb-2">Your notes have been sent!</p>
                        <p class="body-2 text-center">See "Tools" to revoke this share at any time.</p>
                    </template>
                    <template v-else>
                        <v-stepper v-model="step" class="no-bs">
                            <v-stepper-items>
                            
                                <v-stepper-content step="1">
                                    <template v-if="user.notes.length">
                                    <p class="text-center mt-1 mb-3"><v-icon large color="primary">fa fa-share-square</v-icon></p>
                                    <p class="text-center lh-14 title mb-2 font-weight-regular">Sharing Your <span class="hidden-sm-and-down">Emergency</span> Notes</p>
                                    <p class="text-center lh-13 px-1 subtitle-1 mb-0">
                                        Send a custom link to one of your contacts or to a mobile phone number. The link will display 
                                        your chosen notes and will expire after a selected number of days.
                                    </p>
                                    </template>
                                    <template v-else>
                                        <p class="text-center subtitle-1 mt-3">You do not have any emergency notes to share.</p>
                                        <p class="text-center subtitle-1"><v-btn color="primary" to="/me/notes">Manage Notes</v-btn></p>
                                    </template>
                                </v-stepper-content>

                                <v-stepper-content step="2">
                                    <p class="d-block secondary--text text-center lh-15 title mb-3 font-weight-regular">Which notes would you like <br class="hidden-md-and-up" /> to share?</p>
                                    <v-divider />
                                    <div class="pl-md-16 ml-md-16 mt-3">
                                        <template v-for="(item, idx) in userNotes">
                                            <v-checkbox :key="item.title+idx" color="primary" v-model="share.notes" :value="item.uuid" class="my-0 font-weight-medium" :label="item.title" hide-details />
                                        </template>
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="3">
                                    <p class="d-block secondary--text text-center lh-15 title mb-3 font-weight-regular">Who should we send your <br class="hidden-md-and-up" /> {{ notes }} to?</p>
                                    <v-divider />
                                    <template v-if="!useNumber">
                                        <v-select v-model="share.contact" outlined ddense color="primary" :items="contacts" placeholder="Choose From Your Contacts..." hide-details class="mx-auto mt-3 dark-placeholder" />
                                        <p class="text-center mt-1 mb-0">
                                            <span class="d-block text-center mb-2">- or - </span>
                                            <v-btn outlined block color="primary" @click.native="useNumber=true">Share to a Phone Number</v-btn>
                                        </p>
                                    </template>
                                    <template v-else>
                                        <v-text-field outlined style="max-width: 300px;" prefix="+1 " type="tel" placeholder="Ex: (888) 555-5555" label="Phone Number:" class="mt-3 mx-auto" v-mask="'(###) ###-####'" hint="Must be able to receive text messages." :autofocus="!$vuetify.breakpoint.smAndDown" v-model="share.number" />
                                        <p class="text-center mt-3 mb-0">
                                            <v-btn text outlined small color="primary" class="mx-auto" @click.native="useNumber=false">Choose a Contact Instead</v-btn>
                                        </p>
                                    </template>
                                </v-stepper-content>
                                <v-stepper-content step="4">
                                    <p class="d-block secondary--text text-center lh-15 font-weight-regular title mb-3">How long should your {{ notes }} remain viewable?</p>
                                    <v-divider />
                                    <v-select 
                                        outlined
                                        prepend-inner-icon="fa fa-clock"
                                        v-model="share.days"
                                        :prefix="($vuetify.breakpoint.smAndDown) ? 'Share for ' : 'Share my ' + notes + ' for '"
                                        suffix=" ."
                                        style="max-width: 380px"
                                        class="centered-select mt-3 mx-auto"
                                        :items="getDays()"
                                        hide-details
                                    />
                                    <span class="d-block mt-3 text-center secondary--text body-2">Link will expire {{ $moment().add(share.days, 'days').format('dddd, MMMM Do') }}.</span>
                                </v-stepper-content>
                                <v-stepper-content step="5">
                                    <p class="text-center lh-14 title mb-3 font-weight-regular">Confirmation</p>
                                    <v-divider />
                                    <p class="text-center lh-15 subtitle-1 mt-3 mb-2">
                                        You are sending a link for <span class="action-item font-weight-medium" @click="step=2">{{ share.notes.length }} {{ notes }}</span> to: 
                                        <br>
                                        <span class="action-item font-weight-medium" @click="step=3">{{ (share.number) ? share.number : getContact(share.contact) }}</span>. 
                                    </p>
                                    <p class="text-center lh-14 subtitle-1">
                                        The link will expire on <br class="hidden-md-and-up" /> <span class="action-item font-weight-medium" @click="step=4">{{ $moment().add(share.days, 'days').format('dddd, MMMM Do') }}</span>.
                                    </p>
                                    <p class="body-2 text-center mb-0"><v-icon small color="primary" class="mr-1">fa fa-info-circle</v-icon> Link can be revoked at any time.</p>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </template>
                </v-card-text>
                <v-card-actions class="card-actions" v-if="shared">
                    <v-spacer></v-spacer>
                    <v-btn text outlined class="px-5" @click.native="handleShareClose">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-actions class="card-actions" v-else>
                    <v-btn v-if="step==1" text class="px-5" outlined @click.native="handleShareClose">Cancel</v-btn>
                    <v-btn v-if="step>1" text outlined @click.native="--step"><v-icon x-small class="mr-2">fa fa-arrow-left</v-icon> Back</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="step==1" :disabled="!user.notes.length" color="primary" class="px-3" @click.native="++step">Continue <v-icon x-small class="ml-2">fa fa-arrow-right</v-icon></v-btn>
                    <v-btn v-if="step>1 && step<5" color="primary" class="px-6" :disabled="!canContinue" @click.native="++step">Next <v-icon x-small class="ml-2">fa fa-arrow-right</v-icon></v-btn>
                    <v-btn v-if="step==5" color="primary" class="px-3" @click.native="handleShare()" :loading="sharing"><v-icon small class="mr-2">fa fa-share-square</v-icon> Share {{ notes }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'UserDashboardTools',

    props: {
    },
    components: {
    },
    computed: {
        ...mapState(['user']),
        canContinue(){
            if(this.step == 1){
                return this.user.notes.length > 0;
            }
            if(this.step == 2){
                return this.share.notes.length > 0;
            }
            if(this.step == 3){
                return this.share.contact.length > 0 || this.share.number.length == 14;
            }
            if(this.step == 4){
                return this.share.days > 0;
            }
            return true;
        },
        notes(){
            return (this.share.notes.length == 1) ? 'note' : 'notes';
        }
    },
    data: () => ({
        axn: null,
        step: 1,
        testSending: false,
        testSent: false,
        share: {
            notes: [],
            contact: '',
            number: '',
            days: 5
        },
        shared: false,
        sharing: false,
        contacts: [],
        useNumber: false,
        userNotes: []
    }),
    methods: {
        initData(){
            this.userNotes = this.getNotes();
        },
        initShare(){
            this.step = 1;
            for(let i in this.user.contacts){
                this.contacts.push({
                    text: this.user.contacts[i].name + ' - ' + this.user.contacts[i].phone,
                    value: this.user.contacts[i].uuid
                });
            }
            // if(this.user.notes.length == 1){
            //     this.share.notes.push(this.user.notes[0].uuid);
            // }
        },
        getNotes(){
            let noteItems = [];
            let theseNotes = JSON.parse(this.user.notes[0].body);
            for(let i in theseNotes){
                noteItems.push({
                    title: theseNotes[i].title,
                    uuid: theseNotes[i].uuid || theseNotes[i].title,
                    idx: i
                });
            }
            return noteItems;
        },
        getDays(){
            let days = [];
            let day = 'day';
            for(let i = 1; i < 16; ++i){
                if(i>1){
                    day = 'days';
                }
                days.push({
                    value: i,
                    text: i + ' ' + day  
                });
            }
            return days;
        },
        getContact(uuid){
            for(let i in this.user.contacts){
                if(this.user.contacts[i].uuid == uuid){
                    return this.user.contacts[i].name + ' - ' + this.user.contacts[i].phone;
                }
            }
        },
        handleShareClose(){
            this.axn = null;
            this.shared = false;
        },
        getType(idx){
            if(idx == 1){
                return 'Medical Notes';
            }else if(idx == 2){
                return 'Child/Family Care Notes';
            }else if(idx == 3){
                return 'Pet Care Notes';
            }else if(idx == 4){
                return 'Property Notes';
            }

            return 'Other Emergency Notes';
        },
        handleShare(){ 
            console.log('share', this.share);
            // this.share.notes = JSON.stringify(this.share.notes);
            this.sharing = true;
            let request = {
                endpoint: '/notes/share', 
                method: 'post',
                data: this.share,
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$appFn.trackEvent({ action: 'user_action', options: { action_name: 'Notes: Shared Notes' }});
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.sharing = false;
                this.shared = true;
            });
        },
        sendTestSMS(){ 
            this.testSending = true;
            let request = {
                endpoint: '/testsms', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Test message has been sent to: ' + this.user.phone + '.',
                        timeout: 10000
                    });
                }
            }).finally(()=>{
                this.testSending = false;
                this.testSent = true;
            });
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
        this.initData();
    },
    watch: {
        useNumber(){
            this.share.contact = '';
            this.share.number = '';
        },
        axn(){
            if(this.axn == 'share'){
                this.initShare();
            }
            this.share.notes = [];
            this.share.contact = '';
            this.share.number = '';
        }
    }
}
</script>
