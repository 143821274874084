<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu_1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            fullscreen
            :position-x="changeTimePos.x"
            :position-y="changeTimePos.y"
            nudge-top="200px"
            max-width="290px"
            min-width="290px"
            @input="$emit('closed')"
        >
            <v-date-picker v-model="saveTime" :min="$moment().toISOString()" :max="$moment().add(30,'days').toISOString()">
                <div class="date-save d-block">
                    <v-btn text color="white" @click="$emit('closed')">Cancel</v-btn>
                    <v-btn class="float-right px-5" color="primary" @click="save()">Save</v-btn>
                </div>
            </v-date-picker>
        </v-menu>
    </div>
</template>
<script>

export default {

  name: 'CheckinDatesModal',

    props: {
        item: {
            required: true,
            type: String
        },
        changeTimePos: {
            required: true,
            type: Object
        }
    },
    components: {
    },
    computed: {
        
    },
    data: () => ({
        menu_1: true,
        saveTime: null,
    }),
    methods: {
        initData(){
            this.saveTime = JSON.parse(JSON.stringify(this.item));
        },
        allowedMinutes(){
            return [0,5,10,15,20,25,30,35,40,45,50,55];
        },
        save(){
            this.$emit('saved', this.saveTime);
            this.close();
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
        this.initData();
    },
    watch: {
        saveTime(){
            // this.$emit('saved', this.saveTime);
        }
    }
}
</script>
