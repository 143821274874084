<template>
    <v-card class="elevation-2 fill-height hidden-md-and-down" color="white">
        <v-card-text class="px-3 pb-4 pt-3 pt-lg-3 text-center fill-height">
            <div style="width: 100%;">
                <p class="text-center secondary--text settings-title subtitle-1 font-weight-medium mt-0 mb-2">
                    Check-in Stats
                </p>
                <v-row dense class="align-center">
                    <v-col cols="3">
                        <div class="stat-item pa-4">
                            <span class="title primary--text">{{ user.total_checkins || '0' }}</span>
                            <br>
                            <span class="caption secondary--text">Check-Ins</span>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="stat-item pa-4">
                            <span class="title primary--text">{{ getResponseTime() }}</span>
                            <br>
                            <span class="caption secondary--text">Avg. Response Time</span>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div class="stat-item pa-4">
                            <span class="title primary--text">{{ user.total_alerts || '0' }}</span>
                            <br>
                            <span class="caption secondary--text">Alerts Sent</span>
                        </div>
                    </v-col>
                </v-row>
                <v-btn text color="primary" outlined to="/app/activity" class="mt-5 mt-lg-3">View More</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'UserDashboardStats',

    props: {
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        statsInterval: null,
        statsKey: 0,
    }),
    methods: {
        initData(){
            if(this.statsInterval){
                clearInterval(this.statsInterval);
            }
            this.statsInterval = setInterval(() => {
                ++this.statsKey;
            }, 1500);
        },
        getResponseTime(){
            if(!this.user.avg_response_time){
                return 'No Responses';
            }
            let secs = this.user.avg_response_time;
            let mins = Math.ceil(secs / 60);
            let hours = 0;
            if(mins > 59){
                hours = Math.floor(mins / 60);
                mins = mins - (hours * 60);
                if(hours > 1){
                    return hours + 'hrs ' + mins + 'mins';
                }
                return '1hr ' + mins + 'mins';
            }
            if(mins > 1){
                return '~ ' + mins + ' minutes';
            }
            return '< 1 minute';
        }
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
