<template>
    <v-row dense class="settings-tools mt-0 mt-md-0">
        <v-col cols="12" md="12" lg="12" class="pt-0 pb-md-0">
            <p class="text-center text-md-left secondary--text settings-title subtitle-1 font-weight-medium mb-1">
                <v-icon class="ml-md-1 mr-1" color="secondary" size="17">fa fa-cog</v-icon> Check-In Controls
            </p>
            <v-card color="white" class="dd-md-none d-lg-flex elevation-2">
                <v-card-text class="pa-0 pt-0 pt-0 pb-0 px-lg-3">
                    <v-list :disabled="user.on_alert==1" :three-line="$vuetify.breakpoint.smAndDown" :class="(user.on_alert) ? 'py-0 px-4 px-lg-0 no-truncate no-bb faded' : 'py-0 px-4 px-lg-0 no-truncate no-bb'">
                        <v-list-item class="pl-0" v-if="!user.once_checkin" :disabled="!user.settings.active || !!user.settings.snoozed" @click="(user.once_checkin) ? updateOneTime(true) : axn='onetime'">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-clock</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Add a 1-Time Check-in
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Great for first dates and other stranger dangers. Add a check-in on top of your existing schedule.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pl-0 list-no-hover" v-else>
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="success">fa fa-clock</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium success--text">
                                    1-Time Check-in Scheduled
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Will be sent on {{ $moment.utc(user.once_checkin).tz(user.timezone).format('MMMM Do [at] h:mma') }}. 
                                    <v-btn x-small rounded color="error" class="ml-2" @click="$emit('cancelOneTime')" absolute style="margin-top: -2px;" outlined>Cancel</v-btn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item class="pl-0" :disabled="!user.settings.active || !!user.settings.snoozed" @click="(user.settings.snoozed) ? updateSnooze(true) : axn='snooze'">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-bed</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Snooze Your Check-ins
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Unplugging for a bit? Pause your check-ins for a few days. They'll resume automatically when you're done!
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item class="pl-0" :disabled="!user.settings.active" @click="(user.settings.active) ? axn='disable' : deactivate(true)">
                            <v-list-item-avatar class="mr-2 mr-lg-1 mt-1">
                                <v-avatar>
                                    <v-icon style="margin-top: 3px;" color="primary">fa fa-bell-slash</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">
                                    Turn Your Check-ins Off
                                </v-list-item-title>
                                <v-list-item-subtitle class="normal-text">
                                    Stop receiving check-ins until you turn them back on. Takes effect immediately.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-card color="secondary" hover dark class="text-center fill-height d-none dd-md-block dd-lg-none" :disabled="user.once_checkin !== null || !user.settings.active || !!user.settings.snoozed" @click="(user.once_checkin) ? updateOneTime(true) : axn='onetime'">
                <v-icon large color="primary" class="mt-5 mb-3 hidden-lg-and-up">fa fa-clock</v-icon>
                <v-card-title class="d-block text-center py-0">Add a 1-Time Check-In</v-card-title>
                <v-card-text>
                    Venturing out for a first date or worried about other stranger dangers? Add a 1-time check-in on top of your existing schedule.
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="12" class="d-none dd-md-flex dd-lg-none pt-1 pt-md-0">
            <v-card color="secondary" hover dark class="text-center fill-height hidden-md-and-down" :disabled="!!user.settings.snoozed" @click="(user.settings.snoozed) ? updateSnooze(true) : axn='snooze'">
                <v-card-title class="d-block text-center subtitle-1 font-weight-medium py-0 pt-lg-3 pb-lg-2">
                    <v-icon color="primary" class="mr-1 hidden-md-and-down">fa fa-bed</v-icon>
                    Snooze Your Check-Ins
                </v-card-title>
                <v-card-text>
                    Unplugging for a bit? You can pause your check-ins for a few days &mdash; they will resume automatically when you're done!
                </v-card-text>
            </v-card>
            <v-card color="secondary" hover dark class="text-center fill-height hidden-lg-and-up" :disabled="!!user.settings.snoozed" @click="(user.settings.snoozed) ? updateSnooze(true) : axn='snooze'">
                <v-icon large color="primary" class="mt-5 mb-3">fa fa-bed</v-icon>
                <v-card-title class="d-block text-center py-0">Snooze Your Check-Ins</v-card-title>
                <v-card-text>
                    Unplugging for a bit? Good for you! You can pause your check-ins for a few days and we'll resume them automatically when you're done!
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="12" class="d-none dd-md-flex dd-lg-none pt-1 pt-md-0">
            <v-card color="secondary" hover dark class="text-center fill-height hidden-md-and-down" :disabled="!user.settings.active" @click="(user.settings.active) ? axn='disable' : deactivate(true)">
                <v-card-title class="d-block text-center subtitle-1 font-weight-medium py-0 pt-lg-3 pb-lg-2">
                    <v-icon color="primary" class="mr-1">fa fa-bell-slash</v-icon>
                    Disable Your Check-ins
                </v-card-title>
                <v-card-text>
                    You can turn your check-ins back on at any time.
                </v-card-text>
            </v-card>
            <v-card color="secondary" hover dark class="text-center fill-height hidden-lg-and-up" :disabled="!user.settings.active" @click="(user.settings.active) ? axn='disable' : deactivate(true)">
                <v-icon large color="primary" class="mt-5 mb-3">fa fa-bell-slash</v-icon>
                <v-card-title class="d-block text-center py-0">Disable Your Check-Ins</v-card-title>
                <v-card-text>
                    Need to turn-off check-ins for a while but not quite sure when you'll be back? No problem. You can turn your check-ins back on at any time.
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog :value="true" v-if="axn=='snooze'" overlay-opacity="0.8" overlay-color="#000" @input="axn=null" max-width="500px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 secondary--text text-center">Snooze Your Check-Ins</h3>
                    <v-btn absolute right icon @click.native="axn=null"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2 px-2">
                    <v-divider />
                    <p class="flex-center my-4">
                        <v-select 
                            outlined
                            prepend-inner-icon="fa fa-clock"
                            v-model="snoozeDays"
                            prefix="Snooze check-ins for "
                            suffix=" ."
                            style="max-width: 380px"
                            class="centered-select mx-3"
                            :items="getDays()"
                            hide-details
                        />
                        <v-alert type="error" dense width="80%" class="mt-3 mb-0 d-block body-2" v-if="user.once_checkin !== null">
                            Your one-time check-in will be canceled.
                        </v-alert>
                        <span class="d-block mt-3 text-center body-2 lh-12">Your check-ins will automatically resume on <b>{{ $moment().add(snoozeDays+1, 'days').format('dddd, MMMM Do') }}</b>.</span>
                    </p>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-btn text outlined @click.native="axn=null">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="px-3" @click.native="updateSnooze()" :loading="snoozing">Start Snooze</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="true" v-if="axn=='onetime'" overlay-opacity="0.8" overlay-color="#000" @input="axn=null" max-width="500px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 secondary--text text-center">1-Time Check-In</h3>
                    <v-btn absolute right icon @click.native="axn=null"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2 px-2">
                    <v-divider />
                    <div class="flex-center my-4">
                        <p class="secondary--text px-4 text-center title lh-17 pt-2 pt-sm-0">
                            Schedule a 1-time Check-In
                        </p>
                        <p class="text-center mb-0 mx-2" style="max-width: 380px;">
                            <span @click="handleDateClick($event)"><v-text-field readonly class="hover-field prepend-field mb-3" @click="handleDateClick($event)" prefix="Send On: " :value="oneTimeDate" hide-details outlined prepend-inner-icon="fa fa-calendar-alt" /></span>
                            <span @click="handleTimeClick($event)"><v-text-field readonly class="hover-field prepend-field mb-3" prefix="Send At: " :value="getTimeString()" hide-details outlined prepend-inner-icon="fa fa-clock" /></span>
                            <v-select 
                                outlined
                                prepend-inner-icon="fa fa-exclamation-triangle"
                                v-model="oneTime.hours"
                                prefix="Give me "
                                suffix="to respond."
                                class="centered-select hours-select hover-field prepend-field"
                                :items="getHours()"
                                hide-details
                            />
                        </p>
                        <span class="d-block mt-3 body-2">Scheduled check-ins will not be affected.</span>
                    </div>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-btn text outlined @click.native="axn=null">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="px-6" @click.native="updateOneTime()" :loading="oneTiming">Add Check-In</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="true" v-if="axn=='disable'" overlay-opacity="0.8" overlay-color="#000" @input="axn=null" max-width="500px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 secondary--text text-center">Turn Off My Check-Ins</h3>
                    <v-btn absolute right icon @click.native="axn=null"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2">
                    <v-divider />
                    <div class="flex-center my-4">
                        <p class="secondary--text px-5 px-md-10 text-center lh-14 subtitle-1 pt-2 pt-sm-0 mb-1">
                            You will not receive any check-ins until you manually turn them back on.
                        </p>
                    </div>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-btn text outlined @click.native="axn=null">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="px-3" @click.native="deactivate()" :loading="deactivating">Disable Check-Ins</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Times :item="oneTime.time" :selectedDate="oneTime.date" :changeTimePos="changeTimePos" @saved="oneTime.time = $event" v-if="changeTime" @closed="changeTime=null" />
        <Dates :item="oneTime.date" :changeTimePos="changeTimePos" @saved="oneTime.date = $event" v-if="changeDate" @closed="changeDate=null" />
    </v-row>
</template>
<script>

import { mapState } from 'vuex'
import Dates from '@/components/user/dashboard/checkin/Dates'
import Times from '@/components/user/dashboard/checkin/Times2'

export default {

  name: 'UserDashboardStatusBar',

    props: {
    },
    components: {
        Dates,
        Times
    },
    computed: {
        ...mapState(['user']),
        oneTimeDate(){
            return this.$moment(this.oneTime.date).format('MMMM Do');
        }
    },
    data: () => ({
        activating: false,
        deactivating: false,
        axn: null,
        changeTime: null,
        changeTimePos: {},
        changeDate: false,
        snoozeDays: 5,
        snoozing: false,
        testSending: false,
        testSent: false,
        oneTime: {
            date: null,
            time: '17:30',
            hours: 1
        },
        oneTiming: false,
    }),
    methods: {
        initData(){
            this.oneTime.date = this.$moment().format('YYYY-MM-DD');
        },
        handleSnooze(){

        },
        getDays(){
            let days = [];
            let day = 'day';
            for(let i = 1; i < 16; ++i){
                if(i>1){
                    day = 'days';
                }
                days.push({
                    value: i,
                    text: i + ' ' + day  
                });
            }
            return days;
        },
        getHours(){
            let hours = [];
            let hour = 'hour';
            for(let i = 1; i < 17; ++i){
                if(i>1){
                    hour = 'hours';
                }
                hours.push({
                    value: i,
                    text: i + ' ' + hour  
                });
            }
            return hours;
        },
        handleTimeClick(e){
            this.changeTimePos.x = e.clientX;
            this.changeTimePos.y = e.clientY;
            this.changeTime = true;
        },
        handleDateClick(e){
            this.changeTimePos.x = e.clientX;
            this.changeTimePos.y = e.clientY;
            this.changeDate = true;
        },
        getTimeString(){
            return this.formattedTime(this.oneTime.time);
        },
        formattedTime(ts){
            if(!ts){
                return '';
            }
            let parts = ts.split(':');
            let hour = parts[0];
            let ampm = 'am';
            if(hour >= 12){
                ampm = 'pm';
                hour = hour-12;
            }
            if(hour == 0){
                hour = '12';
            }
            return hour + ':' + parts[1] + '' + ampm;
        },
        deactivate(activate){ 
            this.activating = true;
            this.deactivating = true;
            let request = {
                endpoint: '/deactivate', 
                method: 'post',
                data: {},
                apiKey: this.user.api_token || null
            };
            if(activate){
                request.data = {
                    activate: true
                }
            }
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = (activate) ? 'Check-Ins have been turned back on!' : 'Check-Ins have been disabled!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$emit('updated');
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.activating = false;
                this.deactivating = false;
                this.axn=null;
            });
        },
        updateSnooze(unSnooze){ 
            this.snoozing = true;
            let request = {
                endpoint: '/snooze', 
                method: 'post',
                data: {
                    days: this.snoozeDays
                },
                apiKey: this.user.api_token || null
            };
            if(unSnooze){
                request.data = {
                    unsnooze: true
                }
            }
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = (unSnooze) ? 'Snooze has been turned off!' : 'Snooze has been turned on!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$emit('updated');
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.snoozing = false;
                this.axn = null;
            });
        },
        updateOneTime(cancel){ 
            this.oneTiming = true;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    once_date: this.oneTime.date,
                    once_time: this.oneTime.time,
                    once_hours: this.oneTime.hours,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            if(cancel){
                request.data = {
                    once_cancel: true,
                    _method: 'put'
                }
            }
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = (cancel) ? 'One-time Check-in Canceled.' : 'One-time Check-in Scheduled!';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$emit('updated');
                    this.$eventHub.$emit('refreshUser');
                    this.axn = null;
                }else{
                    // this.handleErrorResponse(res);
                    this.$eventHub.$emit('snackBar', {
                        text: 'Invalid time. Please try again.',
                        color: 'error'
                    });
                }
            }).finally(()=>{                
                this.oneTiming = false;
            });
        },
        sendTestSMS(){ 
            this.testSending = true;
            let request = {
                endpoint: '/testsms', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Test message has been sent to: ' + this.user.phone + '.',
                        timeout: 10000
                    });
                }
            }).finally(()=>{
                this.testSending = false;
                this.testSent = true;
            });
        },
        save(){
            this.$emit('saved', true);
            this.close();
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
        this.initData();
    },
    watch: {
        axn(){
            this.changeTime = null;
            this.changeDate = null;
            if(this.axn == 'snooze' || this.axn == 'onetime'){
                this.$emit('snoozing', true);
            }else{
                this.$emit('snoozing', false);
            }
        }
    }
}
</script>
